import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { contactUsForm, contactUsPost } from '../../Redux/Action/ContactUsAction';
import Back from "../common/Back";
import img from "../images/contact2.jpeg";
import "./contact.css";

const Contact = () => {
    const dispatch = useDispatch();
    let contactData = useSelector((state) => state.contactUs);
    const [empty, setEmpty] = useState(0);


    const formEmpty = () => {
        contactData.name = '';
        contactData.email = '';
        contactData.phone = '';
        contactData.website = '';
        contactData.subject = '';
        contactData.message = '';
    }


    const contactAction = (e) => {
        e.preventDefault()
        let data = {
            name: contactData.name,
            email: contactData.email,
            phone: contactData.phone,
            website: contactData.website,
            subject: contactData.subject,
            message: contactData.message
        };

        let l = 0
        for (const [key, value] of Object.entries(data)) {
            if (value === '') l++;
        }

        if (l === 0) {
            dispatch(contactUsPost({ data })).then((res) => {
                formEmpty();
            })

            formEmpty();
        }
        else {
            toast.error('All input is required')
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <section className='contact mb'>
                <Back title='Contact Us' cover={img} />

                <section className="contact-address-area">
                    <div className="container1">
                        <div className="sec-title-style1 text-center max-width">
                            <div className="title">Contact Us</div>
                        </div>
                        <div className="contact-address-box row">
                            <div className="col-sm-4 single-contact-address-box text-center">
                                <div className="icon-holder">
                                    <span className="icon-clock-1">
                                        <span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /><span className="path6" /><span className="path7" /><span className="path8" /><span className="path9" /><span className="path10" /><span className="path11" /><span className="path12" /><span className="path13" /><span className="path14" /><span className="path15" /><span className="path16" /><span className="path17" /><span className="path18" /><span className="path19" /><span className="path20" />
                                    </span>
                                </div>

                                <h2>Ehssas Digitech Private Limited</h2>
                            </div>
                            <div className="col-sm-4 single-contact-address-box main-branch">
                                <h3>Contact</h3>
                                <div className="inner">
                                    <ul>
                                        <li>
                                            <div className="title">
                                                <h4>Address:</h4>
                                            </div>
                                            <div className="text">
                                                <p>5A, 5th Floor, M6, Uppal Plaza, Jasola Vihar, New Delhi, Delhi 110025, India</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="title">
                                                <h4>Ph &amp; Fax:</h4>
                                            </div>
                                            <div className="text">
                                                <p>011-41032346 <br /> contact@ehssasdigitech.com</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="title">
                                                <h4>Office Hrs:</h4>
                                            </div>
                                            <div className="text">
                                                <p>Mon-Fri: 9:30am - 6:30pm<br /> Sat-Sun: Closed</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-4 single-contact-address-box text-center">
                                <div className="icon-holder">
                                    <span className="icon-question-2">
                                        <span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" />
                                    </span>
                                </div>

                                <h2>contact@ehssasdigitech.com</h2>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='container'>
                    <form className='shadow'>
                        <h4 style={{ "textAlign": "center", "marginBottom": "30px" }}>SEND YOUR MESSAGE</h4>
                        <div>
                            <label style={{ "marginRight": "100px" }}>Name</label>
                            <input type='text' style={{ "marginRight": "20px" }} value={contactData.name} onChange={e => dispatch(contactUsForm({ prop: "name", value: e.target.value }))} />
                            <label style={{ "marginRight": "100px" }}>Email</label>
                            <input type='text' value={contactData.email} onChange={e => dispatch(contactUsForm({ prop: "email", value: e.target.value }))} />
                        </div>
                        <div>
                            <label style={{ "marginRight": "95px" }}>Phone</label>
                            <input type="text" style={{ "marginRight": "20px" }} value={contactData.phone} onChange={e => dispatch(contactUsForm({ prop: "phone", value: e.target.value }))} />
                            <label style={{ "marginRight": "75px" }}>Website</label>
                            <input type='text' style={{ "marginLeft": "10px" }} value={contactData.website} onChange={e => dispatch(contactUsForm({ prop: "website", value: e.target.value }))} />
                        </div>
                        <div>
                            <label style={{ "marginRight": "85px" }}>Subject</label>
                            <input type='text' style={{ "marginRight": "40px" }} value={contactData.subject} onChange={e => dispatch(contactUsForm({ prop: "subject", value: e.target.value }))} />
                        </div>
                        <textarea cols='30' rows='10' value={contactData.message} onChange={e => dispatch(contactUsForm({ prop: "message", value: e.target.value }))}></textarea>

                        <button onClick={contactAction}>Submit Request</button>
                    </form>
                </div>
            </section>
        </>
    )
}

export default Contact
